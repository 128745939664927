import React, { useEffect, useRef, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import HeroFeature from "./HeroFeature"
import { colors, screenSizes } from "social-supermarket-model"
import styled from "styled-components"

const Container = styled.div`
  background-color: ${colors.grey};
`
const InnerContainer = styled.div`
  margin: auto;
  max-width: 1500px;
`
const HeroCarouselContainer = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  margin: 0 auto;

  @media (max-width: ${screenSizes.desktop}px) {
    display: none;
  }
`

const FeatureContainer = styled.div`
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  position: relative;
`

const HeroSingle = styled.div`
  @media (min-width: ${screenSizes.desktop}px) {
    display: none;
  }
`
const Child = styled.div`
  height: 100%;
  position: absolute;
  top: -9999px;
  bottom: -9999px;
  left: -9999px;
  right: -9999px;
  margin: auto;
`

const HeroCarousel = ({ className }) => {
  const ref = useRef(null)
  const features = useStaticQuery(queryString).allWp.nodes[0].options.custom.heroFeatures
  const filteredFeatures = features.filter(f => f.active)
  const [selected, setSelected] = useState(0)
  const [fullWidth, setFullWidth] = useState<number>(1500)
  const width = fullWidth / (filteredFeatures.length * 2)
  const selectedWidth = fullWidth - (filteredFeatures.length - 1) * width

  useEffect(() => {
    setFullWidth(ref.current?.clientWidth)
  }, [ref])

  return (
    <Container className={className}>
      <InnerContainer>
        <HeroCarouselContainer ref={ref}>
          {filteredFeatures.map((feature, index) => (
            <FeatureContainer
              key={index}
              onMouseEnter={() => setSelected(index)}
              style={{
                width: index === selected ? selectedWidth : width,
              }}
            >
              <Child style={{ width: selectedWidth }}>
                <HeroFeature feature={feature} hideText={index !== selected} />
              </Child>
            </FeatureContainer>
          ))}
        </HeroCarouselContainer>
        <HeroSingle>
          <HeroFeature feature={filteredFeatures[0]} hideText={false} />
        </HeroSingle>
      </InnerContainer>
    </Container>
  )
}

const queryString = graphql`
  {
    allWp {
      nodes {
        options {
          custom {
            heroFeatures {
              title
              active
              link {
                target
                title
                url
              }
              caption
              colour
              image {
                ...UrlImageFragment
              }
            }
          }
        }
      }
    }
  }
`

export default HeroCarousel
